<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Afmelding nieuwsbrief`">
        <LoadingIcon :isLoading="isLoading" />
        <div  class="px-2" v-if="!isLoading"> 
            <v-container class="pt-0">
                <v-row>
                    <v-col cols="12" class="pt-1" v-if="isValid">
                        We vinden het jammer dat u zich heeft afgemeld voor onze nieuwsbrief, maar bevestigen bij deze dat uw afmelding succesvol in ons systeem is verwerkt.
                    </v-col>
                    <v-col cols="12" class="pt-1" v-if="!isValid">
                        <div>
                            Er is iets misgegaan bij het afmelden van de nieuwsbrief. Heeft u misschien twee keer geklikt? Als u hulp nodig heeft kunt u contact opnemen met 
                            <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a
                                    style="color:#76baff"
                                    target="_blank"
                                    href="mailto:service@roadpursuit.com"
                                    @click.stop
                                    v-on="on">
                                    service@roadpursuit.com
                                </a>
                                </template>
                                Opent uw mail app
                            </v-tooltip>
                            .
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate';
import LoadingIcon from '@/components/common/LoadingIcon';
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'Unsubscribe',
    components: 
    {
        BannerTemplate,
        LoadingIcon
    },
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
        const unsubscribedString = this.$route.params.unsubscribeString;
        this.$store.dispatch('newsletterModule/unsubscribeExistingAccountFromNewsletter', unsubscribedString)
            .then(data => {
                this.isValid = data;
                this.isLoading = false;
            })
            .catch(() => {
                this.isValid = false;
                this.isLoading = false;
            })
    },
    mixins: [MetaTagsMixin],
    data: () => ({
        bannerSource:  require('@/assets/album/gallery_reviews_cover.jpg'),
        isValid: false,
        isLoading: true
    }),
    metaInfo() {
        return {
        title: '| Afmelding nieuwsbrief',
        meta: [
            this.getKeywords(`Afmelding nieuwsbrief`),
            this.getMetaDescription(`Afmelding nieuwsbrief`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Afmelding nieuwsbrief`, `Afmelding nieuwsbrief`,  `${window.location.origin}${this.bannerSource}`, 'Afmelding nieuwsbrief'),
        ]
        };
    },
}
</script>
<style scoped>
p.display-1 {
    padding-top: 15px;
}
</style>